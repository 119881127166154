import { Grid, MenuItem } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AppController from '../../../../../base/App.controller';
import ButtonWrapper from '../../../wrappers/ButtonWrapper';
import DialogWrapper from '../../../wrappers/DialogWrapper';
import SelectWrapper from '../../../wrappers/SelectWrapper';
import DefaultController from './DefaultSettings.controller';
import { IDefaultSettingsModalProps } from '../../types';
import { Itranslation } from 'types';

const DefaultSettingsModal: React.FC<IDefaultSettingsModalProps> = ({
    AppStore,
    BankConfigurationStore,
}) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.host, [
        'bank-configuration',
        'common',
    ]);
    const { standards } = BankConfigurationStore!;

    const {
        type,
        loading,
        isFormChanged,
        handleSave,
        handleStandardSelect,
        isOpen,
        closeModal,
        handleCancel,
        selectedStandard,
    } = BankConfigurationStore!.defaultSettingsModalStore;

    const handleSubmit = () => {
        return handleSave(AppStore);
    };

    return (
        <DialogWrapper
            open={isOpen}
            onClose={closeModal}
            className={'DefaultSettingsModal'}
            title={type === 'add' ? t('enterDefaultSettings') : t('updateDefaultSettings')}>
            <div className={'standards'}>
                <Grid item xs={12}>
                    <SelectWrapper
                        name="selectedStandard"
                        label={t('standard')}
                        value={selectedStandard}
                        onChange={handleStandardSelect}
                        hideOptional>
                        {standards.map((s) => (
                            <MenuItem key={s} value={s}>
                                {t(`standards.${s}`)}
                            </MenuItem>
                        ))}
                    </SelectWrapper>
                </Grid>
            </div>
            <div className={'formContainer'}>
                {DefaultController.getComponent(selectedStandard)}
            </div>
            <div className={'buttonContainer'}>
                <Grid container justify="space-between" spacing={5}>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            color="secondary"
                            className={'closeButton'}
                            onClick={() => handleCancel()}>
                            {t('common:cancel')}
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            size={'large'}
                            color="primary"
                            className={'saveButton'}
                            loading={loading}
                            disabled={!isFormChanged}
                            onClick={handleSubmit}>
                            {t('common:save')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </div>
        </DialogWrapper>
    );
};

export default inject('AppStore', 'BankConfigurationStore')(observer(DefaultSettingsModal));
